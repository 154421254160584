import React from 'react';
import { Link } from "react-router-dom";

import { withContext } from '../../context'

import Menu from './Menu'

function Header({value}) {
	const {identitite, titre, objectifs, keys_words, good_knowledage, formations , cursus,missions } = {...value.CvDatas}
    return( 
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
            <Link to="/" className="navbar-brand" ><img className="logoEntreprise" src={`../images/logo_sasu.png`} alt="Logo entreprise"/></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link" aria-current="page">Accueil</Link>
                </li>
                 
                {
                missions.map((mission, key) => 

                  <Menu key={key} mission= { mission } />
                ) 
                }
                
              </ul>
            </div>
        </div>
      </nav>
	)
}
export default withContext(Header)