import React from 'react';
import Projet  from "./Projet"
import { withContext } from '../context'
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router';
import MissionNotFound from './MissionNotFound';

function Mission({ value }) {
    var ids = [1,2,3,4,5]
    const { id }  = useParams()
    const {identitite, titre, objectifs, keys_words, good_knowledage, formations , cursus,missions } = {...value.CvDatas}

    /*if(!ids.includes(id)){
        return (
            <MissionNotFound />
        )
    }*/
    var currentMission = missions[id - 1];
    
    const months = ["Janv.","Fev.","Mar.","Avr.","Mai","Jui.","Jul.","Aou.","Sept.","Oct.","Nov.","Dec."];
    let dDebut = new Date(currentMission.date_debut)
    let  dateFin = '';
    if(currentMission.date_fin){
        let dFin = new Date(currentMission.date_fin)
        dateFin = months[dFin.getMonth()] +' '+dFin.getFullYear()
    }
   
 
    return(
        <div className="container">
            <section id="mission">
            <div className="enteteMission">
                <div className="imgClient">
                    <h3><span className="styletext">Client</span> : { currentMission.client }</h3>
                    <img className="logo" src={`../images/${currentMission.image}`}   alt={`logo du client ${currentMission.client}`}></img>
                </div>
                <div className="infoClient">
                    <p><span className="styletext">Service</span>:  { currentMission.service } </p>
                    <p><span className="styletext">Lieu</span>: { currentMission.ville } ({ currentMission.cp }) </p>
                    <p><span className="styletext">Début</span>: { months[dDebut.getMonth()] +' '+dDebut.getFullYear() } </p>
                    <p><span className="styletext">Fin</span>: { dateFin } </p>
                </div>
            
            </div>
        
        </section>

        <Projet projets={currentMission.projets} />

       </div>
    )
}
export default withContext(Mission)
