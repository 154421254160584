import React from 'react'
import { withContext }  from "../../context"

function CvTitle({value}){
    const {identitite, titre, objectifs, keys_words, good_knowledage, formations , cursus,missions } = {...value.CvDatas}
    return (
        <div className="titreCV">
            <h2>{titre}</h2>
        </div>
    )
}

export default withContext(CvTitle)