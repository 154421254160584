import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from './reportWebVitals';

import CvDataProvider from "./context"

ReactDOM.render(
  <React.StrictMode>
    <CvDataProvider>
      <App/> 
    </CvDataProvider>   
  </React.StrictMode>,
  document.getElementById("root")
);


reportWebVitals();