import React from "react"
import CvDatas from './datas.json'

export const Context = React.createContext(); /* Context = objet contexte */

const CvDatasProvider = ({ children }) => { 
    const value = React.useMemo(() => {
        return {
            CvDatas,
        }
    }, [])
    return <Context.Provider  value={value}>{ children }</Context.Provider> ;

};

/*composant d'ordre supérieur qui génère un nouveau composant à partir d'un autre .
 Par convention, commence toujours par with....
 On lui transmet un  Component en paramètre.
 On retourne le contexte et l'autre composant faisant partie du contexte
 qui nous retournera une fonction enfant (value) => 
 et dans le callback on va récupérer la value .
 <Component value={value} = compsant généré avec le composant d'ordre sup, on lui transmet la value 
 c'est à dire les valeurs globlales récupées du Context.
 
 Chaq composant qui s'abonne au mise à jour du context sera généré à partir de là */
export const withContext = (Component) => () => {
    return (
        <Context.Consumer>
            {(value) => <Component value={value} /> } 
        </Context.Consumer>
    );
};

export default CvDatasProvider;