import React from 'react';
import Footer from './Footer'
import Header from './Header'
import LeftSidebar from './LeftSidebar'
import CvTitle from './CvTitle';

function Layout({ children }) {
	
   return(
		<div className="layout">
			<Header />
			<CvTitle />
			<LeftSidebar />
			{children}
			<Footer />	
		</div>
	 )
}
export default Layout