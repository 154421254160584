import React from 'react'

function LeftSidebar()  {

    return (
        <div id="blocGauche">
			<div id="strickyVertical">
			<section id="image">
				<span style={{ backgroundImage : `url('../images/joseph_400x400.jpg')` }} className="photo"></span>
			</section>
			<hr></hr>
				<div id="scrollVertical">
					<section id="details_personnels">
						<div>
							<h3>Détails personnels</h3>
							<p><img  className="details" src="../images/user-24.png"  alt="icône utilisateur"></img>&nbsp;J. SILGA</p>
							<p><img  className="details" src="../images/mail-24.png"  alt="icône email"></img>&nbsp;<a href="mailto:silga.dev@gmail.com">silga.dev@gmail.com</a></p>
							<p><img  className="details" src="../images/tel-24.png"  alt="icône telephone"></img>&nbsp;+33 6 05 53 50 94</p>
							<p><img  className="details" src="../images/adresse-24.png"  alt="icône adresse"></img>&nbsp;95300, Pontoise</p>
							<p><img  className="details" src="../images/voiture-24.png"  alt="icône permis"></img>&nbsp;Permis B</p>
							<p><img  className="details" src="../images/linked-24.png"  alt="icône linked"></img>&nbsp;<a href="#">LinkedIn</a></p>
						</div>
					</section>
					<hr></hr>
					<section id="site_web">
						<h3>Site web</h3>
						<p><a href="https://react.josephsilga.net">josephsilga.net</a></p>
						<p><a href="https://avenuedushopping.com" target="_blank">avenuedushopping.com</a></p>
						<p><a href="https://admin.avenuedushopping.com/fr/login" target="_blank">admin.avenuedushopping</a></p>
					</section>
					<hr></hr>
					<section id="langues">
						<h3>Langues</h3>
						
						<p>Français</p>
						<p>Anglais</p>
						
					</section>
					<hr></hr>
					<section id="qualites">
						<h3>Qualités</h3>
						<p>Autonome</p>
						<p>Réactif</p>
						<p>Curieux</p>
					</section>
					<hr></hr>
					<section id="defauts">
						<h3>Défauts</h3>
						<p>Touche à tout</p>
					</section>
					<hr></hr>
					<section id="centres_interets">
						<h3>Centres d'intérêts</h3>
						<ul>
							<li>web</li>
							<li>Lecture</li>
							<li>Football</li>
							<li>Natation</li>
							<li>Politique</li>
						</ul>
					</section>
					<hr></hr>
					<section id="personnes_contacter">
						<h3>Personnes à contacter</h3>
						<p>Jean-Michel C.</p>
						<p>Arnaud D.</p>
							
					</section>
					<hr></hr>
					<section id="disponibilite">
						<h3>Disponibilité</h3>
						<p>3 mois négociable </p>
					</section>
				</div>
			</div>
		</div> 
    )
}

export default LeftSidebar