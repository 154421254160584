import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Mission  from "./components/Mission";
import My404Component from "./components/My404Component"

import "./App.css";


function App() {
  return (
    <Router>
      <Layout>
          <Route exact path="/" render={() => <Home />} />
          <Route path="/mission/:id" render={() => <Mission  />} />
          
      </Layout>
    </Router>
  );
}
export default App;
