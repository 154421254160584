import React from 'react';
import { Link } from "react-router-dom";

function Menu({mission}) {
  const {id, date_debut, date_fin, slug, client, image, service, ville, cp, projets } = {...mission}
 return (
    <li className="nav-item">
          <Link
              to={{
                pathname: `/mission/${id}`,
                props: { mission: mission }, 
              }}
              className="nav-link"
            >
              {client}
            </Link>
    </li>
 )
}
export default Menu;