function Footer() {
	return(
		<footer>
		<div id="reseau">
			<div className="box">
				<a href="#"><i className="fa-brands fa-facebook"></i>
					<h4>Facebook</h4>
					<span>Ma page Facebook </span>
				</a>
		   </div>
		   <div className="box">
				<a href="#"><i className="fa-brands fa-gitlab"></i>
					<h4>Gitlab</h4>
					<span>Mon compte Gitlab</span>
				</a>  
		   </div>
		   <div className="box">
				<a href="#"><i className="fa-brands fa-linkedin"></i>
					<h4>LinkedIn</h4>
					<span>Mon profil LinkedIn</span>
				</a>
		   </div>
		   <div className="box">
				<a href="#"><i className="fa-solid fa-envelope"></i>
					<h4>Contact</h4>
					<span>Contactez-moi</span>
				</a>
			</div>
		</div>
		<p>{new Date().getFullYear()} &copy; Tous droits réservés - <span> Design & implementation : J.SILGA</span></p>
	</footer>
	)
}
export default Footer