import React from 'react'
import { withContext } from '../../context'

function Home({value}) {
  //Récupéation des principales clés
  const {identitite, titre, objectifs, keys_words, good_knowledage, formations , cursus,missions } = {...value.CvDatas}
  //console.log(JSON.stringify(identitite))
    return (
      <div className="container">	
			<section id="objectifs">
				<h3 className="titreObjectif text_gras" >Objectifs</h3>
				<ul>
					<li><img src="../images/approval-14.png" alt="icon approval" />Expertise technique web <span className="text_gras">LAMP (Linux Apache Mysql PHP) & Python</span></li>
					<li><img src="../images/approval-14.png" alt="icon approval" />Pilotage opérationnel des applications</li>
					<li><img src="../images/approval-14.png" alt="icon approval" />Direction opérationnelle des projets</li>
				</ul>
				<p className="text_gras"><span className="text_souligne">Mots clés</span> : PHP, Symfony, Python, API, REST, Redis, SOAP, Chiffrement AES, Docker, Atlas AWS/MongoDB, Matomo,
				SQL, PL/SQL, Bash, XML, Apache, nginx, VBA/Excel, HTML5/CSS3, Gitlab, Jira, Confluence, Agile</p>
				<p><span  className="text_gras text_souligne">Bonnes connaissances </span> : vueJs, React, Angular</p>
			</section>
		
			<div className="card">
				<h3 className="card-header">Formations</h3>
				<div className="card-body">
					<p><span className="text_gras">11/2019 : VBA/Excel</span> chez Orsys</p>
					<p><span className="text_gras">10/2019 : SCRUM Master</span> chez Open Instiut</p>
					<p><span className="text_gras">10/2019 : Python3</span>  chez Open Institut</p>
					<p><span className="text_gras">03/2019 : Angular 7</span> chez Open Institut</p>
					<p><span className="text_gras">12/2018 : Kony Front-End Developer</span>  Certified Expert chez Ascensi </p>
					<p><span className="text_gras">06/2011 : Zend Framework 1.11</span> Chez Orsys</p>
					<p><span className="text_gras">05/2011 : Certification ITIL V3 Foundations </span></p>
					<p><span className="text_gras">2007/2008 : Certifications CISCO CCNA4</span> administration des réseaux informatiques</p>
				</div>
            </div>
			
			<div className="card">
				<h3 className="card-header">Cursus</h3>
				<div className="card-body">
					<p><span className="text_gras">2008/2009 : Mastère Spécialisé en Informatique: Systèmes d'Informations - INSA de Lyon</span></p>
					<p>2005/2008 : Ingénieur en Génie Industriel - Ecole Nationale d'Ingénieurs de Bamako au MALI</p>
					<p>2002/2004 : DUT en Génie Electrique de l'Institut Universitaire de Technologie de Bobo au Burkina Faso</p>
					<p>2001/2002 : Baccalauréat Technologique au Lycée Technique de Ouagadougou au Burkina Faso </p>
				</div>
            </div>

       </div> 
    );
  }
  export default withContext(Home);
