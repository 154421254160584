
import React from 'react';

function Projet({projets}) {

    return(
        projets.map( (projet, key) => 
            <div key={key} className="card">
                <h3 className="card-header"><span className="styletext">Projet</span>  : { projet.nom }</h3>
                <div className="card-body">
                    <h5>{ projet.description }</h5>
                    <p className='textStyle'>Contributions:</p>
                    <ul>
                        {
                            (projet.contributions).map((contribution, k) =>
                            <li key={k}><img src="../images/approval-14.png" alt="icon approval" /> {contribution}</li>
                            )
                        }
                    </ul>
                    
                    <p className='textStyle'>Environnement technique:</p>
                    {
                            (projet.environnement_technique).map((environnement, k) =>
                            <span key={k} className="env">{environnement}</span>
                            )
                    }
                </div>
            </div>
    
        )
    )
}
export default Projet
